import React from "react";
import MenuOptionItem from "./MenuOptionsItem";
import { useSelector } from "react-redux";
import { getCompanyDashboardInfo } from "../../../../selectors";

const PlanAdminOptions = () => {
  const [dashboardInfo] = useSelector((store) => [
    getCompanyDashboardInfo(store),
  ]);

  return (
    <>
      <MenuOptionItem name={"Dashboard"} navLink={"/dashboard"} />
      <MenuOptionItem name={"Company Plan"} navLink={"/company-plan"} />
      <MenuOptionItem name={"Enrolment"} navLink={"/planAdminUserEnrolment"} />
      <MenuOptionItem name={"Employees"} navLink={"/employeeInfo"} />
      {dashboardInfo.companyGuid &&
        dashboardInfo.paymentType.toLowerCase() === "postpay" && (
          <MenuOptionItem name={"Invoices"} navLink={"/planAdminInvoices"} />
        )}
      <MenuOptionItem name={"Reports"} navLink={"/planAdminreports"} />
      <MenuOptionItem name={"Settings"} navLink={"/settings"} />
      {/* <MenuOptionItem name={"Employees"} navLink={"/activity"} />
      <MenuOptionItem name={"Reports"} navLink={"/activity"} />
      <MenuOptionItem name={"Invoices"} navLink={"/activity"} />
      <MenuOptionItem name={"Settings"} navLink={"/activity"} /> */}
    </>
  );
};

export default PlanAdminOptions;
